<template>
  <b-row
    align-h="center"
    align-v="start"
    no-gutters>
    <b-col cols="12" md="9" lg="9" xl="9">
      <div class="form-wrapper">
        <h4 class="text-center">SIGNUP FOR AN ACCOUNT</h4>
        <b-form
          id="free-signup-form"
          @submit.prevent="createFreeAccount">
          <b-input-group prepend="Username">
            <span class="tooltip" v-if="tooltip.username">
              Enter your username here! Use this everytime you log in.
            </span>
            <b-form-input
              id="username"
              type="text"
              placeholder="Username"
              autocomplete="off"
              aria-describedby="signupUsername"
              v-model="signup.username"
              @input="$v.signup.username.$touch()"
              :state="!$v.signup.username.$error"
              @focus="tooltip.username = true"
              @focusout="tooltip.username = false"
            ></b-form-input>
            <b-form-invalid-feedback>
              <span v-if="!$v.signup.username.required">
                Username is required.
              </span>
              <span
                v-if="!$v.signup.username.safeUsername &&
                  $v.signup.username.required">
                Username not allowed.
              </span>
              <span
                v-if="!$v.signup.username.minLength &&
                  $v.signup.username.safeUsername &&
                  $v.signup.username.required">
                Username must be at least
                {{$v.signup.username.$params.minLength.min}}
                characters.
              </span>
              <span
                v-if="!$v.signup.username.uniqueUsername &&
                  $v.signup.username.safeUsername &&
                  $v.signup.username.minLength &&
                  $v.signup.username.required">
                Username is already used.
              </span>
            </b-form-invalid-feedback>
          </b-input-group>

          <b-input-group prepend="Password">
            <span class="tooltip" v-if="tooltip.password">
              Create a password you will use to login. Make sure to
              remember this to log in the next time you visit.
            </span>
            <b-form-input
              id="password"
              type="password"
              placeholder="Password"
              v-model="signup.password"
              aria-describedby="signupPassword"
              @input="$v.signup.password.$touch()"
              :state="!$v.signup.password.$error"
              autocomplete="off"
              @focus="tooltip.password = true"
              @focusout="tooltip.password = false"
            ></b-form-input>
            <b-form-invalid-feedback id="signupPassword">
              <span v-if="!$v.signup.password.required">
                Password is required.
              </span>
              <span v-if="!$v.signup.password.minLength">
                Password must be at least
                {{$v.signup.password.$params.minLength.min}}
                characters
              </span>
            </b-form-invalid-feedback>
          </b-input-group>

          <b-row class="button-wrapper justify-content-center">
            <b-col class="text-center" cols="6" lg="3" xl="3">
              <b-button
                class="mb-2 btn-h3-orange pt-3 pb-3"
                variant="outline-secondary"
                type="submit"
                :disabled="$v.signup.$invalid"
                block>
                Sign Up
              </b-button>
            </b-col>
          </b-row>
        </b-form>

        <AppLoader :isLoading="loading" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
  import _ from 'lodash';
  import { required, minLength } from 'vuelidate/lib/validators';
  import ProfanityMixin from '@/mixins/ProfanityMixin';

  export default {
    mixins : [
      ProfanityMixin,
    ],
    props : {
      birthdate : {
        type : String,
      },
    },
    data() {
      return {
        loading : false,
        signup  : {
          username : null,
          password : null,
        },
        tooltip : {
          username : false,
          password : false,
        },
        safeUsername   : true,
        uniqueUsername : true,
      }
    },
    components : {
      'AppLoader' : () => import('@/components/layout/AppLoader'),
    },
    watch : {
      'signup.username'(val) {
        if (val) {
          this.checkProfanity('safeUsername', val);
          this.checkUsername();
        } else {
          this.safeUsername = true;
          this.uniqueUsername = true;
        }
      },
    },
    methods : {

      /**
       * Create Free Account
       */
      createFreeAccount() {
        this.loading = true;
        this.$http.post('auth/register', {
          nickname : this.signup.username,
          password : this.signup.password,
          birthday : this.birthdate,
          userType : 1,
        }).then(async response => {
          if (response.status == 201) {
            this.$gtag.event('registration', {
              'email'    : this.signup.username,
              'page'     : 'registration',
              'platform' : 'website',
            })

            this.$analytics.fbq.event('registration', {
              'email'    : this.signup.username,
              'page'     : 'registration',
              'platform' : 'website',
            });

            await this.$store.dispatch('auth/login', {
              email    : this.signup.username,
              password : this.signup.password,
            });
          }
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.loading = false;
        });
      },

      /**
       * Check if Username or Nickname exists
       */
      checkUsername : _.debounce(function() {
        this.$http.get('api/user/nickname', {
          params : {
            nickname : this.signup.username,
          },
        }).then(response => {
          this.uniqueUsername = (response.data) ? false : true;
        });
      }, 500),
    },
    validations : {
      signup : {
        username : {
          required,
          minLength : minLength(6), //minLength(8),
          safeUsername() {
            return this.safeUsername;
          },
          uniqueUsername() {
            return this.uniqueUsername;
          },
        },
        password : {
          required,
          minLength : minLength(5),
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  h4 {
    -webkit-text-stroke: 2px $login-purple !important;
  }

  .form-wrapper {
    padding: 3rem 1.5rem;
    background-color: $login-blue;
    border: 10px solid $login-orange;
    border-radius: 1rem;
    box-shadow: 0 -2px 20px rgba(0,0,0,0.5),
      0 15px 20px rgba(0,0,0,0.55);
  }

  .form-control {
    height: auto;
  }

  /* Input */
  .input-group-text {
    width: 10em;
    background-color: $login-orange !important;
    color: #fff;
    font-family: "WickedMouse", cursive;
    font-size: 1em;
    -webkit-text-stroke: 1px $login-purple;
  }

  .invalid-feedback {
    position: absolute;
    top: 2.5em;
    left: 11.5em;
    width: auto;
    color: #fff;
  }

  /* Tooltip */
  .tooltip {
    position: absolute;
    bottom: 115%;
    left: 20%;
    margin-top: .375rem;
    margin-left: 10px;
    border-radius: 6px;
    padding: 5px;
    background-color: $tooltip-color;
    color: #fff;
    font-size: 0.75rem;
    opacity: 1;
    transition: opacity .6s;
    text-align: center;
    visibility: visible;
    z-index: 1;
  }

  .tooltip::after {
    position: absolute;
    top: 100%;
    left: 10%;
    border-color: $tooltip-color transparent transparent  transparent ;
    border-style: solid;
    border-width: .5em;
    content: " ";
    margin-left: -5px;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    h4 {
      padding-bottom: 1.5rem;
      font-size: 1.25em !important;
      -webkit-text-stroke: 1.5px $login-purple !important;
    }
    .button-wrapper {
      padding-top: 1.5rem;
    }
    .input-group-text {
      display: none;
    }
    .invalid-feedback {
      left: 0;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    h4 {
      font-size: 1.5em !important;
      padding-bottom: 1.5rem;
    }
    .button-wrapper {
      padding-top: 1.5rem;
    }
    .input-group-text {
      width: 8em;
    }
    .invalid-feedback {
      left: 10.5em;
    }
  }
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    
  }
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    
  }
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    
  }
  @media only screen and (min-width: $special) {
    h4 {
      padding-bottom: 1.5rem;
    }
    .button-wrapper {
      padding-top: 1.5rem;
    }
    .form-wrapper {
      margin-bottom: 0;
      padding: 2.5rem 1.5rem;
    }
    .input-group-text {
      width: 10em;
    }
    .invalid-feedback {
      left: 13em;
    }
  }
  @media only screen and (min-width: $xl2-min) {
    h4 {
      padding-bottom: 2.5rem;
    }
    .button-wrapper {
      padding-top: 2.5rem;
    }
    .form-wrapper {
      margin-bottom: 10rem;
    }
    .input-group-text {
      width: 10em;
    }
    .invalid-feedback {
      left: 13em;
    }
    .tooltip {
      left: 25%;
    }
  }
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .form-wrapper {
      margin-bottom: 13.5rem;
    }
    .tooltip {
      left: 20%;
    }
  }
</style>