var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{attrs:{"align-h":"center","align-v":"start","no-gutters":""}},[_c('b-col',{attrs:{"cols":"12","md":"9","lg":"9","xl":"9"}},[_c('div',{staticClass:"form-wrapper"},[_c('h4',{staticClass:"text-center"},[_vm._v("SIGNUP FOR AN ACCOUNT")]),_c('b-form',{attrs:{"id":"free-signup-form"},on:{"submit":function($event){$event.preventDefault();return _vm.createFreeAccount.apply(null, arguments)}}},[_c('b-input-group',{attrs:{"prepend":"Username"}},[(_vm.tooltip.username)?_c('span',{staticClass:"tooltip"},[_vm._v(" Enter your username here! Use this everytime you log in. ")]):_vm._e(),_c('b-form-input',{attrs:{"id":"username","type":"text","placeholder":"Username","autocomplete":"off","aria-describedby":"signupUsername","state":!_vm.$v.signup.username.$error},on:{"input":function($event){return _vm.$v.signup.username.$touch()},"focus":function($event){_vm.tooltip.username = true},"focusout":function($event){_vm.tooltip.username = false}},model:{value:(_vm.signup.username),callback:function ($$v) {_vm.$set(_vm.signup, "username", $$v)},expression:"signup.username"}}),_c('b-form-invalid-feedback',[(!_vm.$v.signup.username.required)?_c('span',[_vm._v(" Username is required. ")]):_vm._e(),(!_vm.$v.signup.username.safeUsername &&
                _vm.$v.signup.username.required)?_c('span',[_vm._v(" Username not allowed. ")]):_vm._e(),(!_vm.$v.signup.username.minLength &&
                _vm.$v.signup.username.safeUsername &&
                _vm.$v.signup.username.required)?_c('span',[_vm._v(" Username must be at least "+_vm._s(_vm.$v.signup.username.$params.minLength.min)+" characters. ")]):_vm._e(),(!_vm.$v.signup.username.uniqueUsername &&
                _vm.$v.signup.username.safeUsername &&
                _vm.$v.signup.username.minLength &&
                _vm.$v.signup.username.required)?_c('span',[_vm._v(" Username is already used. ")]):_vm._e()])],1),_c('b-input-group',{attrs:{"prepend":"Password"}},[(_vm.tooltip.password)?_c('span',{staticClass:"tooltip"},[_vm._v(" Create a password you will use to login. Make sure to remember this to log in the next time you visit. ")]):_vm._e(),_c('b-form-input',{attrs:{"id":"password","type":"password","placeholder":"Password","aria-describedby":"signupPassword","state":!_vm.$v.signup.password.$error,"autocomplete":"off"},on:{"input":function($event){return _vm.$v.signup.password.$touch()},"focus":function($event){_vm.tooltip.password = true},"focusout":function($event){_vm.tooltip.password = false}},model:{value:(_vm.signup.password),callback:function ($$v) {_vm.$set(_vm.signup, "password", $$v)},expression:"signup.password"}}),_c('b-form-invalid-feedback',{attrs:{"id":"signupPassword"}},[(!_vm.$v.signup.password.required)?_c('span',[_vm._v(" Password is required. ")]):_vm._e(),(!_vm.$v.signup.password.minLength)?_c('span',[_vm._v(" Password must be at least "+_vm._s(_vm.$v.signup.password.$params.minLength.min)+" characters ")]):_vm._e()])],1),_c('b-row',{staticClass:"button-wrapper justify-content-center"},[_c('b-col',{staticClass:"text-center",attrs:{"cols":"6","lg":"3","xl":"3"}},[_c('b-button',{staticClass:"mb-2 btn-h3-orange pt-3 pb-3",attrs:{"variant":"outline-secondary","type":"submit","disabled":_vm.$v.signup.$invalid,"block":""}},[_vm._v(" Sign Up ")])],1)],1)],1),_c('AppLoader',{attrs:{"isLoading":_vm.loading}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }